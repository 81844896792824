import Section from "components/molecules/section";
import { BannerSlider } from "components/organisms";
import React, { useLayoutEffect } from "react";
import "./styles.scss";
import { Button } from "components/atoms";
import { t } from "i18next";
import { redirectUrlExternal, updateMetadata } from "utils/function";
import { useTranslation } from "react-i18next";

const IkamPaqueteSanValentin = () => {
  const { t, i18n } = useTranslation();

  const datas = {
    banners: [
      {
        id: 1,
        color_fondo_logo: "rgba(62, 145, 41, 1)",
        img_desktop: {
          name: "ikam-banner-san-valentin",
          path: "https://s3.us-east-1.amazonaws.com/ca-webprod/media/ikam-banner-san-valentin.png",
          width: 1440,
          height: 400,
          size: 794666,
          title: "ikam-banner-san-valentin",
          alt: "ikam-banner-san-valentin",
          description: null,
          caption: "ikam-banner-san-valentin",
        },
        img_mobile: {
          name: "ikam-banner-san-valentin",
          path: "https://s3.us-east-1.amazonaws.com/ca-webprod/media/ikam-banner-san-valentin-mobile.png",
          width: 1440,
          height: 400,
          size: 794666,
          title: "ikam-banner-san-valentin",
          alt: "ikam-banner-san-valentin",
          description: null,
          caption: "ikam-banner-san-valentin",
        },
        descuento: "25",
        descripcion_boton: "",
        redireccion_boton: "",
        img_logos: [],
        tipo_banner: {
          id: 1,
          titulo: "solo imagen",
        },
      },
    ],
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);

    updateMetadata({
      title: "iKAM - Paquete San Valentin",
      description:
        "Descubre los paquetes de San Valentín que te trae IKAM este febrero.",
      canonicalUrl: `https://www.casa-andina.com/${i18n.language}/ikam-paquete-san-valentin`,
      hreflang: [
        {
          hreflang: "es",
          href: "https://www.casa-andina.com/es/ikam-paquete-san-valentin",
        },
        {
          hreflang: "en",
          href: "https://www.casa-andina.com/en/ikam-paquete-san-valentin",
        },
        {
          hreflang: "pt",
          href: "https://www.casa-andina.com/pt/ikam-paquete-san-valentin",
        },
      ],
    });
  }, []);

  return (
    <>
      <BannerSlider data={datas.banners} />
      <Section>
        <div className="parrafo__text__ikam_pakete">
          <p className="titulo">
            Descubre los paquetes de San Valentín que te trae IKAM este febrero.
          </p>
          <div className="paragraph">
            <p>
              Vive una escapada única con opciones que incluyen habitación con
              terraza, masajes relajantes, cena romántica y mucho más. ¡Ven a
              disfrutar de una experiencia inolvidable en el corazón de Puerto
              Maldonado!
            </p>
          </div>

          <Button
            type="icongold"
            onClick={() =>
              redirectUrlExternal("mailto:centraldereservas@casa-andina.com")
            }
          >
            Cotiza con nosotros
          </Button>
        </div>
      </Section>
    </>
  );
};

export default IkamPaqueteSanValentin;
