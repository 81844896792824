import {
  Destinations,
  DestinationCity,
  Home,
  HotelCity,
  Hotels,
  NotFound,
  Error,
  Maintenance,
  Promotions,
  PromotionsQuota,
  PromotionsSubPages,
  Restaurants,
  PromotionsBetterPrices,
  News,
  ResponsabilidadSocial,
  DogFriendly,
  TerminosyCondiciones,
  TerminosyCondicionesLatamPass,
  Directores,
  CuotasSinIntereses,
  MomentosCasaAndina,
  PoliticaUsoImagen,
  QuienesSomos,
  AcumulaLatamPass,
  TerminosYCondicionesdeReserva,
  PersonalDataProtectionPolicy,
  PersonalClientDataProtectionPolicy,
  PromotionsBank,
  CheckinPiura,
  CheckinCusco,
} from "pages";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Layout } from "components/organisms";
import {
  PromotionsBanks,
  RestaurantCategory,
  RestaurantRestaurant,
  RestaurantsTemplate,
} from "components/templates";
import HotelCategories from "pages/hotels/categories";
import {
  Agencies,
  Arco,
  Comprobantes,
  Contacto,
  Eventos,
  FriendsFamily,
  IkamPaqueteSanValentin,
  PoliticasPrivacidad,
  PreguntasFrecuentes,
  Tarifascorporativas,
} from "pages/landings";

import { useTranslation } from "react-i18next";
import { SUPPORT_LANG } from "utils/valuesForms";

const RoutesIndex = () => {
  const { i18n } = useTranslation();
  const storedLanguage = localStorage && localStorage.getItem("i18nextLng");
  const browserLanguage = navigator.language;

  const defaultLanguage = "es";

  const redirectTo = storedLanguage
    ? `/${i18n.language}/home`
    : SUPPORT_LANG.includes(browserLanguage)
    ? `/${browserLanguage}/home`
    : `/${defaultLanguage}/home`;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={redirectTo} replace />} />
        <Route path="/:lang" element={<Layout />}>
          <Route path="home" element={<Home />} />
          {/* URL: /destinos */}
          <Route path={"destinos"} element={<Destinations />} />
          <Route path={"destinations"} element={<Destinations />} />
          <Route path={"destinacoes"} element={<Destinations />} />
          {/* URL='destinos/:city'  */}
          <Route path={`destinos/:city`} element={<DestinationCity />} />
          <Route path={`destinations/:city`} element={<DestinationCity />} />
          <Route path={`destinacoes/:city`} element={<DestinationCity />} />

          {/* URL='hoteles' */}
          <Route path={"hoteles"} element={<Hotels />} />
          <Route path={"hotels"} element={<Hotels />} />
          <Route path={"hoteis"} element={<Hotels />} />

          {/* path='destinos/:city/hoteles/:place' */}
          <Route
            path={`destinos/:city/hoteles/:place`}
            element={<HotelCity />}
          />
          <Route
            path={`destinations/:city/hotels/:place`}
            element={<HotelCity />}
          />
          <Route
            path={`destinacoes/:city/hoteis/:place`}
            element={<HotelCity />}
          />

          {/* URL: /hoteles/:types  */}
          <Route path={`hoteles/:types`} element={<HotelCategories />} />
          <Route path={`hotels/:types`} element={<HotelCategories />} />
          <Route path={`hoteis/:types`} element={<HotelCategories />} />

          {/* URL='ofertas' */}
          <Route path={"ofertas"} element={<Promotions />} />
          <Route path={"offers"} element={<Promotions />} />

          {/* URL: /ofertas/:subpages  */}
          <Route path={`ofertas/:subpages`} element={<PromotionsSubPages />} />
          <Route path={`offers/:subpages`} element={<PromotionsSubPages />} />

          {/* URL: /ofertas/cuota-sin-intereses */}
          <Route
            path={`ofertas/cuotas-sin-intereses`}
            element={<PromotionsQuota />}
          />
          <Route
            path={`offers/cuotas-sin-intereses`}
            element={<PromotionsQuota />}
          />

          {/* URL: /restaurantes */}
          <Route path={"restaurantes"} element={<Restaurants />} />
          <Route path={"restaurants"} element={<Restaurants />} />

          <Route
            path={`ofertas/restaurantes/bancos`}
            element={<PromotionsBank />}
          />
          <Route
            path={`offers/restaurants/banks`}
            element={<PromotionsBank />}
          />

          {/* URL: /ofertas/restaurantes/:category */}
          <Route
            path={`ofertas/restaurantes/:category`}
            element={<RestaurantRestaurant />}
          />
          <Route
            path={`offers/restaurants/:category`}
            element={<RestaurantRestaurant />}
          />

          {/* URL: /mejores-precios */}
          <Route
            path={"mejores-precios"}
            element={<PromotionsBetterPrices />}
          />
          <Route path={"better_price"} element={<PromotionsBetterPrices />} />
          <Route
            path={"melhores-precos"}
            element={<PromotionsBetterPrices />}
          />

          {/* URL: /restaurantes/:places */}
          <Route
            path={`restaurantes/:places`}
            element={<RestaurantsTemplate />}
          />
          <Route
            path={`restaurants/:places`}
            element={<RestaurantsTemplate />}
          />

          {/* URL: /restaurantes/:city/:restaurant  */}
          <Route
            path={`restaurantes/:city/:restaurant`}
            element={<RestaurantCategory />}
          />
          <Route
            path={`restaurants/:city/:restaurant`}
            element={<RestaurantCategory />}
          />

          {/* URL: /eventos */}
          <Route path={"eventos"} element={<Eventos />} />
          <Route path={"events"} element={<Eventos />} />

          {/* URL: /agencias */}
          <Route path={"reservas-agentes"} element={<Agencies />} />
          <Route path={"reservations-agents"} element={<Agencies />} />
          <Route path={"agentes-de-reserva"} element={<Agencies />} />

          {/* URL: /terminos-y-condiciones */}
          <Route
            path="terminos-y-condiciones"
            element={<TerminosyCondiciones />}
          />
          <Route
            path="terms-and-conditions"
            element={<TerminosyCondiciones />}
          />
          <Route path="termos-e-condicoes" element={<TerminosyCondiciones />} />

          {/* URL: /terminos-y-condiciones-de-reservas-en-hoteles-asociados */}
          <Route
            path="terminos-y-condiciones-de-reservas-en-hoteles-asociados"
            element={<TerminosYCondicionesdeReserva />}
          />
          <Route
            path="terms-and-conditions-for-bookings-at-associated-hotels"
            element={<TerminosYCondicionesdeReserva />}
          />
          <Route
            path="termos-e-condicoes-de-reservas-em-hoteis-associados"
            element={<TerminosYCondicionesdeReserva />}
          />

          {/* URL: /terminos-y-condiciones-de-reservas-en-hoteles-asociados */}
          <Route
            path="politica-de-proteccion-de-datos-personales"
            element={<PersonalDataProtectionPolicy />}
          />
          <Route
            path="personal-data-protection-policy"
            element={<PersonalDataProtectionPolicy />}
          />
          <Route
            path="politica-de-protecao-de-dados-pessoais"
            element={<PersonalDataProtectionPolicy />}
          />

          {/* URL: /terminos-y-condiciones-de-reservas-en-hoteles-asociados */}
          <Route
            path="politica-de-proteccion-de-datos-personales-clientes"
            element={<PersonalClientDataProtectionPolicy />}
          />

          {/* URL: /politica-web-de-privacidad */}
          <Route
            path="politica-web-de-privacidad"
            element={<PoliticasPrivacidad />}
          />
          <Route path="web-privacy-policy" element={<PoliticasPrivacidad />} />
          <Route
            path="politica-web-de-privacidade"
            element={<PoliticasPrivacidad />}
          />

          {/* URL: /responsabilidad-social */}
          <Route
            path={"responsabilidad-social"}
            element={<ResponsabilidadSocial />}
          />
          <Route
            path={"social-responsability"}
            element={<ResponsabilidadSocial />}
          />
          <Route
            path={"responsabilidade-social"}
            element={<ResponsabilidadSocial />}
          />

          {/* URL: /como-acceder-a-los-comprobantes */}
          <Route
            path="tarifas-corporativas"
            element={<Tarifascorporativas />}
          />
          <Route path="corporate-rates" element={<Tarifascorporativas />} />
          <Route path="taxas-corporativas" element={<Tarifascorporativas />} />

          {/* URL: /como-acceder-a-los-comprobantes */}
          <Route
            path="como-acceder-a-los-comprobantes"
            element={<Comprobantes />}
          />

          <Route path="dog-friendly" element={<DogFriendly />} />

          <Route path="checkin/piura" element={<CheckinPiura />} />
          <Route path="checkin/cusco" element={<CheckinCusco />} />

          <Route
            path="terminos-y-condiciones-latam-pass"
            element={<TerminosyCondicionesLatamPass />}
          />
          <Route path="contacto" element={<Contacto />} />
          <Route path="arco" element={<Arco />} />

          <Route
            path="preguntas-frecuentes"
            element={<PreguntasFrecuentes />}
          />
          <Route path="news" element={<News />} />
          <Route path="quienes-somos" element={<QuienesSomos />} />
          <Route path="friends-and-family" element={<FriendsFamily />} />
          <Route path="acumula-latam-pass" element={<AcumulaLatamPass />} />

          <Route path="directores" element={<Directores />} />
          {/* <Route path='cuotas-sin-intereses' element={<CuotasSinIntereses />} /> */}
          <Route path="momentos-casa-andina" element={<MomentosCasaAndina />} />
          <Route
            path="politica-uso-imagen-concurso-UCG"
            element={<PoliticaUsoImagen />}
          />
          <Route
            path={"ikam-paquete-san-valentin"}
            element={<IkamPaqueteSanValentin />}
          />

          <Route path="error" element={<Error />} />
          <Route path="*" element={<NotFound />} />
          <Route path="404" element={<NotFound />} />
          <Route path="mantenimiento" element={<Maintenance />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesIndex;
